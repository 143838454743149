import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

const RES_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  name
  street
  number
  box
  zip
  state
  city
  country
  countryCode
  countryKey
  other
  lat
  lng
`;

export const newAddress = async () => {
	try {
		const {
			data: { NewAddress }
		} = await apollo.mutate({
			mutation: gql`
				mutation {
					NewAddress {
						${RES_FIELD}
					}
				}
			`
		});
		return NewAddress;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} transportId
 * @param {Object} payload
 * @return {Object}
 */
export const updateAddress = async (addressId, payload) => {
	if (isNil(addressId)) return;
	try {
		const {
			data: { UpdAddress }
		} = await apollo.mutate({
			mutation: gql`
				mutation ($AddressId: ID!, $UpdatedAddress: AddressInput!) {
					UpdAddress(AddressId: $AddressId, UpdatedAddress: $UpdatedAddress) {
						${RES_FIELD}
					}
				}
			`,
			variables: {
				AddressId: parseInt(addressId),
				UpdatedAddress: payload
			}
		});
		return Object.assign({ id: addressId }, UpdAddress);
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {Object} payload
 * @return {Object}
 */
export const createAddress = async (payload) => {
	console.log({ createAddress: payload });
	const { id: addressId } = await newAddress();
	console.log({ 11111111: addressId });
	const address = await updateAddress(addressId, payload);
	console.log({ 2222222: address });
	return address;
};

/**
 * @param {String|Number} transportId
 */
export const getAddress = async (addressId) => {
	if (isNil(addressId)) return;
	try {
		const {
			data: { GetAddress }
		} = await apollo.query({
			query: gql`
				query ($AddressId: ID!) {
					GetAddress(AddressId: $AddressId) {
            ${RES_FIELD}
					}
				}
			`,
			variables: {
				AddressId: parseInt(addressId)
			},
			fetchPolicy: 'network-only'
		});
		return Object.assign({ id: addressId }, GetAddress);
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {Number|String} addressId
 */
export const delAddress = async (addressId) => {
	if (isNil(addressId)) return;
	try {
		await apollo.query({
			mutation: gql`
				mutation ($AddressId: ID!) {
					DelAddress(AddressId: $AddressId)
				}
			`,
			variables: {
				AddressId: parseInt(addressId)
			}
		});
	} catch (e) {
		console.log({ e });
	}
};
